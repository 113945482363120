function initSingleAlumni() {
    var $ = jQuery;
    // $('.alumni__images').each(function(index, obj){
    //   $(obj).slick({
    //     dots: false,
    //     infinite: false,
    //     speed: 300,
    //     slidesToShow: 1,
    //     centerMode: false,
    //     variableWidth: true
    //   });
    // });

    // Event for button to show short facts

    $('.alumni__short--btn').on("click", function() {
        $('.alumni__short').addClass('lightbox');
        $('.alumni__short').fadeIn();
    });
    $('.lightbox-close').on("click", function() {
        $('.alumni__short').removeClass('lightbox');
        $('.alumni__short').fadeOut();
    });
    // Inits slider for images on alumni

    var pswpElement = document.querySelectorAll('.pswp')[0];
    var pswpImages = [];
    var pswpIndex = 0;
    // build items array
    $('.alumni__psvp-image').each(function(index, obj) {
        //console.log($(obj));
        $(obj).parent('.alumni__image').attr('data-index', pswpIndex);
        var data = {
            src: $(obj)[0].currentSrc,
            w: $(obj)[0].naturalWidth,
            h: $(obj)[0].naturalHeight,
            title: $(obj).data('text')
        };

        var found = false;
        for(var i = 0; i < pswpImages.length; i++) {
            if (pswpImages[i].src === $(obj)[0].currentSrc) {
                found = true;
                $(obj).parent('.alumni__image').attr('data-index', i);
                break;
            }
        }
        if(!found) {
            pswpIndex++;
            pswpImages.push(data);
        }
    });

    // Initializes and opens PhotoSwipe
    $('.alumni__image').on("click",function () {
        var options = {
            index: $(this).data('index'),
            bgOpacity: 0.95
        };

        var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, pswpImages, options);
        gallery.init();
    });

}
