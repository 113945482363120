/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {

                  function setCookie(cname, cvalue, exdays) {
                      var d = new Date();
                      d.setTime(d.getTime() + (exdays*24*60*60*1000));
                      var expires = "expires="+d.toUTCString();
                      document.cookie = cname + "=" + cvalue + "; " + expires + ";path=/";
                  }

                  function getCookie(cname) {
                      var name = cname + "=";
                      var ca = document.cookie.split(';');
                      for(var i=0; i<ca.length; i++) {
                          var c = ca[i];
                          while (c.charAt(0)===' ') {
                            c = c.substring(1);
                          } 
                          if (c.indexOf(name) === 0) {
                            return c.substring(name.length, c.length);
                          }
                      }
                      return "";
                  }

                  $('.cookiemessage__approve').click(function(){
                    setCookie("cookiemessage", 'approved', 30);
                    $('.cookiemessage').addClass('approved');
                  });



                $(window).on('wpcf7mailsent', function() {
                    $('.signupmodal .modal-body .wpcf7-form').hide();
                    $('.signupmodal .modal-body').append('<span class="messagesent">Message sent</span>');
                });
                $('.imageslideshow__imagecontainer').flickity({
                    wrapAround: true,
                });

                $('.signupmodal').on('hidden.bs.modal', function (e) {
                    $('.signupmodal .modal-body .wpcf7-form').show();
                    $('.signupmodal .modal-body .messagesent').remove();
                });

                $('body').addClass('tobefaded');
                $( window ).load(function() {
                  setTimeout(function(){
                    $('body.tobefaded').addClass('displayed');
                  }, 0);
                });

                // JavaScript to be fired on all pages
                var menu_options = {
                    toggleElement: '.hamburger',
                    openBodyClass: 'fullscreen-menu-open',
                    menuElement: 'menu',
                    menuInnerElement: '.menu__container'
                };
                $('.navbar-fullscreen').fullScreenMenu(menu_options);

                $('.posts__single--morebtn').on("click", function(e){
                    e.preventDefault();
                    $('.posts__single-content').addClass('posts__single-content--open');
                    $(this).parent('.posts__single--more').fadeOut();
                });

                $('#myModal').modal({ show: false});
                //$('.people__item--inner').on('click', function(event) {
                //    event.preventDefault();
                //    header = $(this).find('.people__item--name').html();
                //    biotext = $(this).find('.people__item--bio').html();
                //    $('.modal-title').html(header);
                //    $('.modal-body').html(biotext);
                //    $('#myModal').modal('show');
                //});
                $('.people__item--inner a').on('click', function(event) {
                    event.stopPropagation();
                });


                initAlumni();

                
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired

                $('.search-open a').click(function(event) {
                    event.preventDefault();
                    $('.search__wrapper').slideToggle( "fast", function() {});
                    $('.header__menu .menu').slideToggle( "fast", function() {});
                    $('.search-field').focus();
                });
            }
        },
        // About us page, note the change from about-us to about_us.
        'our_alumni': {
            init: function() {
            }
        },
        'single_alumni': {
            init: function() {
                initSingleAlumni();
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
