function initAlumni() {
    var $ = jQuery;
    var pagination = 1;

    $('.alumni__grid').masonry({
      isAnimated: true,
      itemSelector: '.alumni__item',
      columnWidth: '.alumni__item',
      percentPosition: true
    });
    $('.button__load').click(function(event) {
      event.preventDefault();
      var button = $(this);
      button.addClass('loading');
      $.ajax({
          type: "POST",
          url: ajaxobject.ajax_url,
          data: ({ action: 'ajax_post', posts: 6, post_type: 'alumni', pagination: pagination}),
          success: function(data){
              button.removeClass('loading');
              if(data) {
                $('.alumni__grid').append(data).masonry( 'appended', $('.alumni__' + pagination));
                pagination++;
              } else {
                $('.load-link').html("No more stories");
              }
          }
      });
    });
}
