(function ($) {
	$.fn.touchClick = function (callback) {
		var flag = false;

		$(this).bind('touchstart click', function () {
			if (!flag) {
				flag = true;
				setTimeout(function () { flag = false; }, 100);
				
				$(this).trigger('click');
				
				callback();

				return false;
			}
		});
	};
})(jQuery);