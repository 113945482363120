(function ($) {
	$.fn.fullScreenMenu = function(options) {

		// Remove rubber effect
		var menuElement = document.querySelector('.' + options.menuElement);
		menuElement.addEventListener("touchstart", function() {
			var top = menuElement.scrollTop;
			var totalScroll = menuElement.scrollHeight;
			var currentScroll = top + menuElement.offsetHeight;

			if (top === 0) {
				menuElement.scrollTop = 1;
			} else if (currentScroll === totalScroll) {
				menuElement.scrollTop = top - 1;
			}
		});

		// Prevent scrolling on some cases
		// document.ontouchmove = function (event) {
		// 	if ($('body').hasClass(options.openBodyClass)) {
		// 		var targets = $(event.target).parents().andSelf();
		// 		if (!(targets.is('.' + options.menuElement)) || $(options.menuInnerElement).outerHeight(true) < menuElement.offsetHeight) {
		// 			event.preventDefault();
		// 		}
		// 	}
		// };

		// Toggler
		var toggleElement = $(options.toggleElement);
		var open = false;
		toggleElement.touchClick(function () {
			// Hide other if open
			$.each(options.hide, function( index, value ) {
				$('.' + value).removeClass(value + '--open').addClass(value + '--hidden');
			});


			menuElement.scrollTop = 1;
			$('body').toggleClass(options.openBodyClass);
			if(!open){
				$('body').removeClass('fullscreen-closed');
				setTimeout(function(){
					$('.menu__main').addClass('open');
				}, 600);
			} else {
				$('.menu__main').removeClass('open');
				$('body').addClass('fullscreen-closed');
			}
			$('.' + options.menuElement).toggleClass(options.openMenuClass);
			$('.' + options.menuElement).focus();
			open = !open;
		});

	};
})(jQuery);
