(function ($) {

    $('.slider__hero').each(function(index, obj){
        $(obj).on('init', function (event, slick, direction) {
            // check to see if there are one or less slides
            console.log();

            if ($(obj).find('.slick-slide').length <= 1) {
                $(obj).find('.slick-dots').hide();
            }
        });
        $(obj).slick({
            prevArrow:"<button class='prev slick-prev'><span class='slick-btn-text'>Prev. Slide</span></button>",
            nextArrow:"<button class='next slick-next'><span class='slick-btn-text'>Next Slide</span></button>",
            autoplay: true,
            autoplaySpeed: 6700,
            pauseOnFocus: false,
            adaptiveHeight: true,
            dots: true
        });
    });

    $('.slider__posts').each(function(index, obj){
        var previous_button_text  = $(obj).attr('data-prev');
        var next_button_text      = $(obj).attr('data-next');
        var columns               = parseInt($(obj).attr('data-columns'));

        $(obj).slick({
            prevArrow:"<button class='prev slick-prev'><span class='slick-btn-text slick-btn-black'>" + previous_button_text + "</span></button>",
            nextArrow:"<button class='next slick-next'><span class='slick-btn-text slick-btn-black'>" + next_button_text + "</span></button>",
            autoplay: false,
            dots: false,
            infinite: false,
            slidesToShow: columns,
            slidesToScroll: columns,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true
                    }
                }
            ]
        });
    });


    var lastVal = 0;
    var canChange = true;
    var teaseActive = false;
    var added = false;

    function teaseSlider(el, teaseNext, reset) {
        var pos = el.css('transform');
        var results = pos.match(/matrix(?:(3d)\(-{0,1}\d+(?:, -{0,1}\d+)*(?:, (-{0,1}\d+))(?:, (-{0,1}\d+))(?:, (-{0,1}\d+)), -{0,1}\d+\)|\(-{0,1}\d+(?:, -{0,1}\d+)*(?:, (-{0,1}\d+))(?:, (-{0,1}\d+))\))/);
        var difference = 70;
        if(!results) { return [0, 0, 0]; }
        if(results[1] === '3d') { return results.slice(2,5); }

        var value = parseInt(results.slice(5,6)[0]); // returns the [X,Y,Z,1] values

        if(canChange){
            if(reset && teaseNext){ value = lastVal;}
            if(reset && !teaseNext){ value = lastVal;}
            if(!reset && teaseNext){
                lastVal = value;
                el.addClass('tease-active');
                value = value - difference;
            }
            if(!reset && !teaseNext){
                lastVal = value;
                el.addClass('tease-active');
                value = value + difference;
            }
            el.addClass('animate');

            teaseActive = true;
            el.css({ transform: "translate3d(" + value + "px, 0, 0)" });
            setTimeout(function(){
                el.removeClass('animate');
                teaseActive = false;
                added = true;
                if(reset) {
                    added = false;
                }
            }, 500);
        } else {
            canChange = true;
            lastVal = value;
        }
    }

    $('.slick-next')
    .on('mouseenter', function(){
        if(!added){
            var el = $(this).siblings('.slick-list').children('.slick-track');
            teaseSlider(el,true,false);
        }
    })
    .on('mouseleave', function(){
        var el = $(this).siblings('.slick-list').children('.slick-track');
        if(added){
            teaseSlider(el,true,true);
        } else {
            setTimeout(function(){
                teaseSlider(el,true,true);
            }, 500);
        }
    })
    .on('click', function(){
        canChange = false;
    });

    $('.slick-prev')
    .on('mouseenter', function(){
        if(!added){
            var el = $(this).siblings('.slick-list').children('.slick-track');
            teaseSlider(el,false,false);
        }
    })
    .on('mouseleave', function(){
        var el = $(this).siblings('.slick-list').children('.slick-track');
        if(added){
            teaseSlider(el,false,true);
        } else {
            setTimeout(function(){
                teaseSlider(el,true,true);
            }, 500);
        }
    })
    .on('click', function(){
        canChange = false;
    });

})(jQuery);
